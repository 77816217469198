import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";

import {
	getURLParam,
	getURLPath,
	getMediaURL,
	getThumbnail,
} from "../libs/utils";

import "normalize.css";

const firebaseInstance = `rick-and-morty-242714`;
const siteURL = `http://media.rickflector.rocks`;

// outer container
const Container = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: black;
	color: white;
`;

// video element
const Video = styled.video`
	max-width: 90%;
	max-height: 90%;
`;

// image element
const Image = styled.img`
	max-width: 90%;
	max-height: 90%;
`;

// embed the media object in the page
export default function MediaWrapper() {
	// state
	const [mediaType, setMediaType] = useState();
	const [playerID, setPlayerID] = useState();
	const [mediaURL, setMediaURL] = useState();
	const [thumbnailURL, setThumbnailURL] = useState();

	// on page load
	useEffect(() => {
		// get the media type
		setMediaType(getURLPath());

		// get the player id
		setPlayerID(getURLParam(`id`).replace(`"`, ``));
	}, []);

	// after the media type and player id is grabbed
	useEffect(() => {
		// create the url
		setMediaURL(
			getMediaURL({ type: mediaType, id: playerID, firebaseInstance })
		);
		setThumbnailURL(getThumbnail({ id: playerID, firebaseInstance }));
	}, [mediaType, playerID]);

	// render
	if (mediaURL === undefined) return <></>;
	return (
		<>
			<Helmet>
				<title>Your Rickflection</title>
				<meta property="fb:app_id" content="343932749878228" />
				<meta name="twitter:site" content="@adultswim" />
				<meta name="twitter:creator" content="@adultswim" />
				<meta
					property="og:url"
					content={`${siteURL}/${mediaType}?id=${playerID}`}
				/>
				<meta property="og:title" content="My Rickflection" />
				<meta
					property="og:description"
					content="I got my DNA scrambled at the #AdultSwimFestival"
				/>
			</Helmet>
			<Container>
				{mediaType === `videos` ? (
					<>
						<Video
							src={mediaURL}
							type="video/mp4"
							controls
							autoplay
						/>
						<Helmet>
							<meta property="og:type" content="video.other" />
							<meta property="og:image" content={thumbnailURL} />
							<meta property="og:image:width" content="600" />
							<meta property="og:image:height" content="600" />
							<meta property="og:video" content={mediaURL} />
							<meta
								property="og:video:type"
								content="video/mp4"
							/>
							<meta property="og:video:width" content="1080" />
							<meta property="og:video:height" content="1920" />
							<meta name="twitter:card" content="player" />
							<meta name="twitter:player" content={mediaURL} />
							<meta name="twitter:player:width" content="1080" />
							<meta name="twitter:player:height" content="1920" />
							<meta name="twitter:image" content={thumbnailURL} />
						</Helmet>
					</>
				) : (
					<>
						<Image src={mediaURL} />
						<Helmet>
							<meta property="og:type" content="website" />
							<meta property="og:image" content={mediaURL} />
							<meta
								property="og:image:type"
								content="image/png"
							/>
							<meta property="og:image:width" content="870" />
							<meta property="og:image:height" content="870" />
							<meta
								name="twitter:card"
								content="summary_large_image"
							/>
						</Helmet>
					</>
				)}
			</Container>
		</>
	);
}
